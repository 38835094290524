import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Collapse, Grid, IconButton, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HttpAuthenticationType, HttpDeviceConfig } from '@dametis/core';

import {
  createAuthorizationHeader,
  createBasicAuthentication,
  createQueryParamAuthentication,
} from 'components/Configuration/Devices/helpers/createHttpDeviceConfig';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

export interface HttpAuthentificationFormProps {
  http: HttpDeviceConfig;
  setHttp: Dispatch<SetStateAction<HttpDeviceConfig>>;
  containerWidth: number;
  isEditing?: boolean;
}

export const HTTP_AUTHENTICATION_TYPE_NONE = 'NONE';

const HttpAuthentificationForm: FC<HttpAuthentificationFormProps> = ({ http, setHttp, containerWidth, isEditing = true }) => {
  const { t } = useTranslation('devices');

  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const availableTypes = useMemo(
    () => [
      HttpAuthenticationType.BASIC,
      HttpAuthenticationType.AUTHORIZATION_HEADER,
      HttpAuthenticationType.QUERY_PARAM,
      HTTP_AUTHENTICATION_TYPE_NONE,
    ],
    [],
  );

  const type = useMemo(
    () => (http.authentication === null ? HTTP_AUTHENTICATION_TYPE_NONE : http.authentication.type),
    [http.authentication],
  );

  const handleChangeType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.target.value === HttpAuthenticationType.BASIC) {
        setHttp(state => ({ ...state, authentication: createBasicAuthentication() }));
      } else if (event.target.value === HttpAuthenticationType.AUTHORIZATION_HEADER) {
        setHttp(state => ({ ...state, authentication: createAuthorizationHeader() }));
      } else if (event.target.value === HttpAuthenticationType.QUERY_PARAM) {
        setHttp(state => ({ ...state, authentication: createQueryParamAuthentication() }));
      } else {
        setHttp(state => ({ ...state, authentication: null }));
      }
    },
    [setHttp],
  );

  const handleChangeUsername: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => {
        if (state.authentication?.type === HttpAuthenticationType.BASIC) {
          return { ...state, authentication: { ...state.authentication, username: event.target.value } };
        }
        return state;
      });
    },
    [setHttp],
  );

  const handleTogglePasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPasswordHidden(state => !state);
  }, []);

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => {
        if (state.authentication?.type === HttpAuthenticationType.BASIC) {
          return { ...state, authentication: { ...state.authentication, password: event.target.value } };
        }
        return state;
      });
    },
    [setHttp],
  );

  const handleChangeAuthorizationHeader: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => {
        if (state.authentication?.type === HttpAuthenticationType.AUTHORIZATION_HEADER) {
          return { ...state, authentication: { ...state.authentication, authorizationHeader: event.target.value } };
        }
        return state;
      });
    },
    [setHttp],
  );

  const handleChangeKey: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => {
        if (state.authentication?.type === HttpAuthenticationType.QUERY_PARAM) {
          return { ...state, authentication: { ...state.authentication, key: event.target.value } };
        }
        return state;
      });
    },
    [setHttp],
  );

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => {
        if (state.authentication?.type === HttpAuthenticationType.QUERY_PARAM) {
          return { ...state, authentication: { ...state.authentication, value: event.target.value } };
        }
        return state;
      });
    },
    [setHttp],
  );

  return (
    <Stack>
      <AdvancedTextField fullWidth select editing={isEditing} label={t('label.type')} value={type} onChange={handleChangeType}>
        {availableTypes.map(availableType => (
          <MenuItem key={availableType} value={availableType}>
            {t(`httpAuthenticationType.${availableType}`)}
          </MenuItem>
        ))}
      </AdvancedTextField>
      <Collapse in={type === HttpAuthenticationType.BASIC}>
        <Grid container pt={1} spacing={1}>
          <Grid item xs={containerWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.username')}
              value={http.authentication?.type === HttpAuthenticationType.BASIC ? http.authentication.username : ''}
              onChange={handleChangeUsername}
            />
          </Grid>
          <Grid item xs={containerWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={isPasswordHidden ? t('tooltip.hidePassword') : t('tooltip.seePassword')}>
                      <IconButton edge="end" size="large" onClick={handleTogglePasswordVisibility}>
                        {isPasswordHidden ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              label={t('label.password')}
              type={isPasswordHidden ? 'password' : 'text'}
              value={http.authentication?.type === HttpAuthenticationType.BASIC ? http.authentication.password : ''}
              onChange={handleChangePassword}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={type === HttpAuthenticationType.AUTHORIZATION_HEADER}>
        <Grid container pt={1} spacing={1}>
          <Grid item xs={12}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.value')}
              value={
                http.authentication?.type === HttpAuthenticationType.AUTHORIZATION_HEADER ? http.authentication.authorizationHeader : ''
              }
              onChange={handleChangeAuthorizationHeader}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={type === HttpAuthenticationType.QUERY_PARAM}>
        <Grid container pt={1} spacing={1}>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.key')}
              value={http.authentication?.type === HttpAuthenticationType.QUERY_PARAM ? http.authentication.key : ''}
              onChange={handleChangeKey}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.value')}
              value={http.authentication?.type === HttpAuthenticationType.QUERY_PARAM ? http.authentication.value : ''}
              onChange={handleChangeValue}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Stack>
  );
};

export default HttpAuthentificationForm;
