import {
  BugReport,
  BugReportOutlined,
  Build,
  BuildOutlined,
  Business,
  BusinessOutlined,
  Dataset,
  DatasetOutlined,
  Explore,
  ExploreOutlined,
  Factory,
  FactoryOutlined,
  Home,
  HomeOutlined,
  MiscellaneousServices,
  MiscellaneousServicesOutlined,
  MonitorHeart,
  MonitorHeartOutlined,
  Numbers,
  NumbersOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  Router,
  RouterOutlined,
  Security,
  SecurityOutlined,
  Storage,
  StorageOutlined,
  Task,
  TaskOutlined,
} from '@mui/icons-material';

import { Route, RouteScope } from 'types/drawer';

import SubRoutes from './SecondaryDrawerContents/SubRoutes';

export const adminRoutes: Route[] = [
  {
    name: 'home',
    icon: HomeOutlined,
    activeIcon: Home,
    url: '/',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'dataHistory',
    icon: DatasetOutlined,
    activeIcon: Dataset,
    url: '/admin/dataHistory',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'sites',
    icon: FactoryOutlined,
    activeIcon: Factory,
    url: '/admin/sites',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'groups',
    icon: BusinessOutlined,
    activeIcon: Business,
    url: '/admin/groups',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'users',
    icon: PeopleAltOutlined,
    activeIcon: PeopleAlt,
    url: '/admin/users',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'Users',
            url: '/admin/users',
            scopes: [RouteScope.SITE, RouteScope.GROUP],
          },
          {
            name: 'Roles',
            url: '/admin/roles',
            scopes: [RouteScope.SITE, RouteScope.GROUP],
          },
        ]}
      />
    ),
  },

  {
    name: 'boxes',
    icon: RouterOutlined,
    activeIcon: Router,
    url: '/admin/boxes',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'variables',
    icon: NumbersOutlined,
    activeIcon: Numbers,
    url: '/admin/variables',
    permission: 'canAccessConfiguration',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'debuggus',
    icon: BugReportOutlined,
    activeIcon: BugReport,
    url: '/admin/debuggus',
    permission: 'canAccessConfiguration',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'exploration',
    icon: ExploreOutlined,
    activeIcon: Explore,
    url: '/admin/exploration',
    permission: 'canAccessWIP',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'activities',
    icon: TaskOutlined,
    activeIcon: Task,
    url: '/admin/activities',
    permission: 'canAccessActivity',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'authentications',
    icon: SecurityOutlined,
    activeIcon: Security,
    url: '/admin/authentications',
    permission: 'canAccessAuthentication',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'backup',
    icon: StorageOutlined,
    activeIcon: Storage,
    url: '/admin/backup',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'monitoring',
    icon: MonitorHeartOutlined,
    activeIcon: MonitorHeart,
    url: '/admin/monitoring',
    permission: 'canAccessDamco',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'misc',
    icon: MiscellaneousServicesOutlined,
    activeIcon: MiscellaneousServices,
    url: '/admin/misc',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },

  {
    name: 'maintenance',
    icon: BuildOutlined,
    activeIcon: Build,
    url: '/admin/maintenance',
    permission: 'canAccessBo',
    scopes: [RouteScope.ADMIN],
  },
];
