import { DeleteOutlineOutlined, EditOutlined, PlayCircleOutlined, SaveOutlined } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { MouseEvent, MouseEventHandler, forwardRef, useCallback, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import PromptBeforeDeleting, { PromptBeforeDeletingProps } from '../PromptBeforeDeleting/PromptBeforeDeleting';

export type HeaderPrimaryRightButtonProps = LoadingButtonProps & {
  variant?: 'text' | 'contained';
  special?: 'modify' | 'delete' | 'execute';
  active?: boolean;
  color?: ButtonProps['color'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  to?: NavLinkProps['to'];
};

const HeaderPrimaryRightButton = forwardRef<HTMLButtonElement, HeaderPrimaryRightButtonProps>(
  ({ special, variant, active, children, color, onClick, to, ...props }, ref) => {
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
      setDeleteDialog(true);
    }, []);

    const handleConfirmDelete = useCallback<PromptBeforeDeletingProps['onDelete']>(
      arg => {
        onClick?.(arg as MouseEvent<HTMLButtonElement>);
      },
      [onClick],
    );

    return (
      <>
        <LoadingButton
          color={(special !== undefined && special === 'delete' && 'error') || (active === true && 'secondary') || color}
          size="small"
          startIcon={
            active ? (
              <SaveOutlined />
            ) : (
              {
                modify: <EditOutlined />,
                delete: <DeleteOutlineOutlined />,
                execute: <PlayCircleOutlined />,
                noIcon: undefined,
              }[special ?? 'noIcon']
            )
          }
          sx={{ ml: 1, flexShrink: 0, flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
          variant={variant || (special === 'modify' && 'contained') || undefined}
          onClick={special === 'delete' ? handleDelete : onClick}
          {...(to && { component: NavLink, to })}
          {...props}
          ref={ref}
        >
          {children}
        </LoadingButton>
        {special === 'delete' && onClick !== undefined && (
          <PromptBeforeDeleting open={deleteDialog} setOpen={setDeleteDialog} onDelete={handleConfirmDelete} />
        )}
      </>
    );
  },
);

HeaderPrimaryRightButton.defaultProps = {
  special: undefined,
  variant: undefined,
  active: false,
  color: 'primary',
  onClick: undefined,
  to: undefined,
};

HeaderPrimaryRightButton.displayName = 'PageTitleButton';

export default HeaderPrimaryRightButton;
