import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import type { Axis } from 'highcharts';

import {
  AnyGroupBy,
  CalculationVariable,
  EntityStyleConfiguration,
  GroupByUnion,
  MacroField,
  PeriodWithDashStyle,
  PlaygroundInfo,
  RegressionType,
  Smoothing,
  StyledRule,
  TabInfo,
  TabType,
  TadaApiResponse,
  UUID,
} from '@dametis/core';

import DaArearangeSeries from 'classes/DaCharts/DaArearangeSeries';
import { DaAxis } from 'classes/DaCharts/DaAxis';
import DaCategorySeries from 'classes/DaCharts/DaCategorySeries';
import { DaChart } from 'classes/DaCharts/DaChart';
import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import DaScatterSeries from 'classes/DaCharts/DaScatterSeries';
import HTMLDivRef from 'classes/HTMLDivRef/HTMLDivRef';
import MuiApiRef from 'classes/MuiApiRef/MuiApiRef';
import { FormatResult } from 'types/format';

// PLAYGROUND

export interface IPlayground extends Omit<PlaygroundInfo, 'tabs'> {
  tabs: ITab[];
}

// TABS

export interface ITab<C extends IChart = IChart> extends Omit<TabInfo, 'chart'> {
  chart: C;
  customPeriod?: IPeriodWithDashStyle[];
}

export interface IPeriodWithDashStyle extends PeriodWithDashStyle {
  xAxis?: Axis;
}

export const IsTyChartTab = (tab: any): tab is ITab<ITyChart> => tab?.type === TabType.TY_CHART || tab?.type === TabType.BAR_CHART;

export const IsLiveChartTab = (tab: any): tab is ITab<ILiveChart> => tab?.type === TabType.LIVE_CHART;

export const IsXyChartTab = (tab: any): tab is ITab<IXyChart> => tab?.type === TabType.XY_CHART;

export const IsBarChartTab = (tab: any): tab is ITab<IBarChart> => tab?.type === TabType.BAR_CHART;

export const IsHistogramTab = (tab: any): tab is ITab<IHistogram> => tab?.type === TabType.HISTOGRAM;

export const IsTableTab = (tab: any): tab is ITab<ITable> => tab?.type === TabType.TABLE;

// CHARTS

export interface IChart {
  uuid: string;
  preview: string;
  previewPath: string;
  isUserOverride?: boolean;
}

export type IChartProps = Partial<IChart>;

export type WithDaChart<T> = T & {
  daChart: DaChart;
};

export const HasDaChart = (tab: any): tab is ITab<WithDaChart<IChart>> => tab?.chart?.daChart !== undefined;

export const HasApiRef = (tab: any): tab is ITab<ITable> => tab?.chart?.apiRef !== undefined;

export type WithCustomTimeRange<T> = T & {
  customTimeRange: ITimeRange;
};

export const HasCustomTimeRange = (tab: any): tab is ITab<WithCustomTimeRange<IChart>> => tab?.chart?.customTimeRange !== undefined;

export interface IGroup {
  uuid: string;
  name: string;
  unit: string | null;
  min: number | null;
  max: number | null;
}

export interface IVariable {
  uuid: string;
  name: string;
}

export type IVariableProps = Partial<IVariable>;

// TY CHART

export interface ITyChart extends WithCustomTimeRange<WithDaChart<IChart>> {
  groups: ITyGroup[];
  isBatchChart?: number | boolean;
  isBarChart?: boolean;
  groupBy: GroupByUnion<true, true, false, true, false>;
}

export type ITyChartProps = Partial<ITyChart>;

export interface ITyGroup extends IGroup {
  variables: ITyVariable[];
  yAxis?: DaAxis;
  hidden: boolean;
}

export type ITyGroupProps = Partial<ITyGroup>;

export interface ITyVariable extends IVariable {
  expression: CalculationVariable;
  daSeries: DaLineSeries;
  unit: string | null;
  apiUnit: string;
  color: string | null;
  dashStyle?: string;
  hidden: boolean;
  groupUuid: UUID;
  format?: FormatResult;
  smoothing?: Smoothing;
  type?: 'bar' | 'line';
}

export type ITyVariableProps = Partial<ITyVariable>;

export enum ThresholdDirection {
  ABOVE = 'above',
  UNDER = 'under',
}

// LIVE CHART

export interface ILiveChart extends WithCustomTimeRange<WithDaChart<IChart>> {
  variables: ILiveVariable[];
}

export type ILiveChartProps = Partial<ILiveChart>;

export interface ILiveVariable extends IVariable {
  expression: CalculationVariable;
  daSeries: DaLineSeries;
  unit: string;
  color: string;
  min: number;
  max: number;
  socketRoomId: string;
  socketHandler: (data: TadaApiResponse) => unknown;
  hidden: boolean;
}

export type ILiveVariableProps = Partial<ILiveVariable>;

// BAR CHART

export interface IBarGroup extends IGroup {
  variables: ITyVariable[];
  yAxis?: DaAxis;
  hidden: boolean;
}

export interface IBarChart extends WithDaChart<IChart> {
  groups: IBarGroup[];
  variables?: IBarVariable[];
  groupBy: AnyGroupBy;
}

export type IBarChartProps = Partial<IBarChart>;

export interface IBarVariable extends IVariable {
  expression: CalculationVariable;
  daSeries: DaLineSeries;
  unit: string;
  color: string;
}

export type IBarVariableProps = Partial<IBarVariable>;

// HISTOGRAM

export interface IHistogram extends WithDaChart<IChart> {
  variable: IHistogramVariable;
}

export type IHistogramProps = Partial<IHistogram>;

export interface IHistogramVariable extends IVariable {
  expression: CalculationVariable;
  daSeries: DaCategorySeries;
  unit: string;
  color: string;
  min: number;
  max: number;
  step: number;
}

export type IHistogramVariableProps = Partial<IHistogramVariable>;

// XY CHART

export const isXVariableIndex = (v: IXyChart['xVariable']): v is string => {
  return typeof v === 'string';
};

export interface IXyChart extends WithDaChart<IChart> {
  yVariables: IXyVariable[];
  xVariable: IXyVariable | string;
  groupBy: GroupByUnion<true, true, false, true, false>;
}

export type IXyChartProps = Partial<IXyChart>;

export interface XyAreaRangeLimits {
  min: number;
  max: number;
}
export interface XyAreaRangeParams extends IVariable {
  color: string;
  left: XyAreaRangeLimits;
  right: XyAreaRangeLimits;
}

export interface IXyVariable extends IVariable {
  expression: CalculationVariable;
  daSeries?: DaScatterSeries;
  unit: string;
  min?: number | null;
  max?: number | null;
  color?: string;
  visible: boolean;
  regression: IRegression;
  regressionSeries?: DaLineSeries;
  regressionParams?: IRegressionParams;
  areaRangeSeries?: DaArearangeSeries[];
  areaRangeParams?: XyAreaRangeParams[];
  styledRules?: StyledRule[] | EntityStyleConfiguration;
}

export type IXyVariableProps = Partial<IXyVariable>;

// TABLE

export interface ITable extends IChart {
  variables: ITableVariable[];
  apiRef: MuiApiRef;
  gridState: GridInitialStatePremium;
  macro: ITableMacroSettings | null;
  groupBy: GroupByUnion<true, true, false, true, false>;
  ref: HTMLDivRef;
  batchTarget?: number;
  selectedRows?: GridValidRowModel[];
  isLoading?: boolean;
}

export interface ITableMacroSettings {
  uuid: UUID;
  pivot?: string | null;
  config: IMacroColsSettings[];
  selectedResultKey?: string;
}

export interface IMacroColsSettings extends MacroField {
  label: string;
  visible: boolean;
  color: string;
  styledRules?: StyledRule[] | EntityStyleConfiguration;
}

export type IMacroColsProps = Partial<IMacroColsSettings>;

export const IsMacroCol = (field: any): field is IMacroColsSettings =>
  field.name !== undefined && field.description !== undefined && field.unit !== undefined && field.type !== undefined;

export enum ICellType {
  VAR = 'var',
  HEADER = 'header',
  DURATION = 'duration',
}

export type ITableProps = Partial<ITable>;

export interface ITableVariable extends IVariable {
  expression: CalculationVariable;
  unit: string;
  format?: FormatResult;
  color: string;
  index: string;
  styledRules?: StyledRule[] | EntityStyleConfiguration;
  tyVisibility?: boolean; // boolean to display or not a var on a ty chart generated from a batch table (elodys)
}

export type ITableVariableProps = Partial<ITableVariable>;

export const IsBatchGroupBy = (groupBy: any): groupBy is CalculationVariable => groupBy?.exp !== undefined && groupBy?.vars !== undefined;

// TIME RANGE

export interface ITimeRange {
  from: Date;
  to: Date;
}

export type ITimeRangeProps = Partial<ITimeRange>;

// REGRESSION

export type IRegression = RegressionType | false;

export const regressionArr = Object.values(RegressionType).filter(r => !Number.isFinite(r));

export interface IRegressionCoefficient {
  coefficient: number;
  importance: number;
  xVarIndex?: number; // used in models for disabled xVariables
}

export interface IRegressionParams {
  formula: string;
  coefficients: Array<IRegressionCoefficient>;
  r2: number | null;
  mape?: number | null;
  rmse?: number | null;
}

export const marginCoef = 0.17;
