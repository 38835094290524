const getUserName = (user?: { firstName?: string; lastName?: string }) => {
  if (!user) {
    return '';
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user.firstName) {
    return user.firstName;
  }

  if (user.lastName) {
    return user.lastName;
  }

  return '';
};

export default getUserName;
