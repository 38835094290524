import DOMPurify from 'dompurify';
import type { NavigationBindingsOptionsObject, Options, SVGElement, SelectEventObject, SeriesLineOptions } from 'highcharts';
import Chart from 'highcharts/es-modules/Core/Chart/Chart';
import SVGRenderer from 'highcharts/es-modules/Core/Renderer/SVG/SVGRenderer';
import Exporting from 'highcharts/es-modules/Extensions/Exporting/Exporting';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/Extensions/NoDataToDisplay/NoDataToDisplay';
import 'highcharts/modules/annotations';
import 'highcharts/es-modules/masters/modules/annotations.src';
import 'highcharts/es-modules/masters/modules/annotations-advanced.src';
import 'highcharts/es-modules/masters/modules/stock-tools.src';
import i18next, { t } from 'i18next';
import { SerializedEditorState, SerializedElementNode } from 'lexical';
import ReactDOM from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';

import 'highcharts/es-modules/Series/Scatter/ScatterSeries';
import 'highcharts/es-modules/Series/Area/AreaSeries';
import 'highcharts/es-modules/Extensions/PatternFill';
import 'highcharts/es-modules/Series/XRange/XRangeSeries';
import 'highcharts/es-modules/masters/modules/boost.src';

import { Period, Shortcut, UUID, UpdateCommentBody } from '@dametis/core';
import highchartsMulticolor from '@dametis/highcharts-multicolor-series';
import { UnitName } from '@dametis/unit';

import ChartComment from 'components/UI/ChartAnnotation/ChartComment';
import ChartSegmentLabel from 'components/UI/ChartAnnotation/ChartSegmentLabel';
import { generateNotifications } from 'components/UI/Comments/Comment/Edit/plugins/MentionPlugin/functions/MentionPluginFunctions';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import Wrapper from 'components/Wrapper/Wrapper';
import { updateUrlPeriod } from 'functions/dateInUrl';
import { shortDurationDisplay } from 'functions/durationDisplay';
import { getContent, getStyledRules } from 'functions/styledRule';
import { localizedNumber } from 'localization/index';
import { localizedFormat } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import store from 'store';
import { updatePeriod } from 'store/actions/period';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { api } from 'store/api';
import { selectStyleConfigurations } from 'store/api/styleConfigurations';
import { editComment, openComment, setIsCommentEdit } from 'store/slices/playground';
import { addToast } from 'store/slices/toast';
import { theme } from 'theme';
import { ToastSeverity } from 'types';

import DaArearangeSeries from './DaArearangeSeries';
import DaAreasplineSeries from './DaAreasplineSeries';
import { DaAxis, DaAxisProps } from './DaAxis';
import DaBarSeries from './DaBarSeries';
import DaCategorySeries from './DaCategorySeries';
import DaLineSeries from './DaLineSeries';
import DaPieSeries from './DaPieSeries';
import DaScatterSeries from './DaScatterSeries';
import { DaSeries, IChartType } from './types';

import './DaChart.css';

Exporting.compose(Chart, SVGRenderer);
highchartsMulticolor(Highcharts);

export const BOOST_POINTS_THRESHOLD = 5000;

export interface DaChartOptions extends Partial<Omit<Options, 'legend'>> {
  periodZoom: boolean;
  legend: boolean;
  type: IChartType;
  height?: string | number | null;
  displayPeriod?: {
    from: Date;
    to: Date;
  };
}
export interface CallbackFunction {
  (this: Chart, chart: Chart): void;
}

export class DaChart extends Chart {
  unitsMinMax: Record<string, { min: number; max: number }>;

  declare options: Options;

  periodZoom: boolean;

  reflowTimeout: ReturnType<typeof setTimeout> | null;

  batchArea?: {
    left: SVGElement | null;
    right: SVGElement | null;
    center: SVGElement | null;
    goldenCIP: SVGElement | null;
  };

  displayPeriod?: {
    from: Date;
    to: Date;
  };

  isZoomed: boolean;

  public constructor(htmlElement: HTMLElement | Options, options?: DaChartOptions | CallbackFunction, callback?: CallbackFunction) {
    const overrideOptions: Options = {
      ...options,
      accessibility: {
        enabled: false,
      },
      lang: {
        noData: i18next.t('dachart:label.noData'),
        loading: i18next.t('dachart:label.loading'),
      },
      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
      chart: {
        ...(options as Options)?.chart,
        animation: true,
        displayErrors: true,
        reflow: true,
        zooming: {
          resetButton: {
            theme: {
              fill: 'white',
              'stroke-width': 1,
              stroke: '#708898',
              style: {
                color: '#708898',
                display:
                  (options as DaChartOptions)?.periodZoom ||
                  (options as DaChartOptions)?.type === 'scatter' ||
                  (options as DaChartOptions)?.type === 'category'
                    ? 'none '
                    : 'box',
              },
              states: {
                hover: {
                  fill: '#edf0f2',
                },
              },
            },
          },
          type: 'x',
        },
        height: (options as DaChartOptions)?.height ?? null,
        backgroundColor: 'transparent',
        events: {
          selection: (e: SelectEventObject & Event) => {
            if ((options as DaChartOptions)?.periodZoom) {
              e.preventDefault();
              if (e.xAxis !== undefined) {
                const period = new Period({
                  from: new Date(e.xAxis[0].min),
                  to: new Date(e.xAxis[0].max),
                });
                updateUrlPeriod(period.from, period.to);
                store.dispatch(updatePeriod(period));
              }
              return false;
            }
            return true;
          },
        },
        style: {
          fontFamily: 'Work Sans, sans-serif',
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: (options as DaChartOptions)?.legend,
        padding: 0,
        itemStyle: {
          fontSize: '12px',
          fontWeight: '500',
        },
        margin: 15,
      },
      time: {
        useUTC: false,
      },
      xAxis: {
        ...(options as Options)?.xAxis,
        tickPositioner() {
          const { tickPositions } = this;
          const { displayPeriod, isZoomed } = this.chart as DaChart;
          if (displayPeriod && !isZoomed) {
            tickPositions.splice(0, 1);
            tickPositions.splice(tickPositions.length - 1, 1);
            tickPositions[0] = displayPeriod.from.getTime();
            const lastTick = tickPositions.length - 1;
            tickPositions[lastTick === 0 ? 1 : lastTick] = displayPeriod.to.getTime();
          }
          return tickPositions;
        },
        events: {
          setExtremes(event) {
            (this.chart as DaChart).isZoomed = !(!event.min && !event.max);
          },
        },
        type: { category: 'category', scatter: 'value' }[(options as DaChartOptions)?.type] ?? 'datetime',
        lineColor: theme.palette.grey[200],
        tickColor: theme.palette.grey[200],
        alignTicks: true,
        title: {
          text: null,
        },
        id: uuidv4(),
        crosshair:
          (options as DaChartOptions)?.type === 'pie'
            ? null
            : {
                width: 1,
                color: theme.palette.grey[600],
                dashStyle: 'Dash',
              },
        labels: {
          style: {
            color: theme.palette.grey.A200,
            fontSize: '10px',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          useHTML: true,
          formatter() {
            if ((options as DaChartOptions)?.type === 'line' && (this.isFirst || this.isLast)) {
              return `<div style="text-align: center">${localizedFormat(this.value as number, 'p')}</div><div style="text-align: center; ${
                this.isLast &&
                'position: absolute; width: 300%;right:0px; oveflow-wrap: break-word; white-space: normal; text-align: right;'
              }">${localizedFormat(this.value as number, 'eee PP')}</div>`;
            }
            return this.axis.defaultLabelFormatter.call(this);
          },
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        crosshair:
          (options as DaChartOptions)?.type === 'pie' ? null : { snap: false, width: 1, color: theme.palette.grey[600], dashStyle: 'Dash' },
      },
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
        },
        line: {
          marker: {
            enabledThreshold: 2,
          },
          connectNulls: true,
        },
        pie: {
          showInLegend: true,
          dataLabels: {
            distance: 10,
            enabled: true,
            formatter() {
              const data = getFormattedValue({
                value: this.point.y,
                baseUnit: this.point.options.custom.unit,
                userUnit: this.point.options.custom.selectedUnit,
              });
              return `${data} (${localizedNumber(this.point.percentage, [1, 1])}%)`;
            },
            style: {
              fontWeight: 'normal',
              textOverflow: 'clip',
            },
          },
        },
      },
      tooltip: {
        shared: false,
        followPointer: true,
        useHTML: true,
        xDateFormat: '%Y-%m-%d',
        animation: false,
        formatter() {
          let dateX = '';
          switch ((options as DaChartOptions)?.type) {
            case 'pie':
              return null;
            // break;
            case 'category':
              dateX = this.series.xAxis.userOptions.labels.formatter.call({ value: this.point.name }); // does not work all the time
              break;
            case 'scatter':
              dateX = `${
                this.point.custom?.time ? `<div>${this.point.custom.time}</div>` : ''
              }<div style="margin-left: auto">X: ${getFormattedValue({ value: this.point.x })}</div>`;
              break;
            default:
              dateX = `${localizedFormat(this.point.x, 'eee PPpp')}`;
          }
          let toolTip = `<div style="border: 1px solid ${theme.palette.grey[200]};box-shadow: 0 10px 15px -3px ${theme.palette.grey[900]}40, 0 4px 6px -2px ${theme.palette.grey[900]}60; background-color: white; border-radius: 4px; padding: 0.3rem; font-size: 13px">`;
          toolTip += dateX?.length
            ? `<div style="margin-bottom: 0.2rem; text-align: center; font-size: 12px; display: flex; padding: 0 0.4rem; color: ${theme.palette.text.secondary}">${dateX}</div>`
            : '';
          const data = [];
          if (this.series.type === 'pie') {
            this.series.chart.series[0]?.data.forEach(point => {
              if (point.y != null) {
                data.push({
                  id: (point as unknown as { id: number }).id,
                  formattedValue: getFormattedValue({
                    value: point.y,
                    baseUnit: point.options.custom.unit,
                    userUnit: point.options.custom.selectedUnit,
                  }),
                  color: point.color,
                  name: point.name,
                });
              }
            });
          } else if (this.point.x !== undefined) {
            this.series.chart.series
              .filter(s => {
                return (options as DaChartOptions)?.type === 'scatter'
                  ? (s.options?.type === 'line' && s.options.visible) || (s.options?.type !== 'arearange' && s.options?.type !== 'line')
                  : s.options.visible;
              })
              .forEach(s => {
                let index: number;
                let pointY: number;
                if ((options as DaChartOptions)?.type === 'scatter') {
                  index = DaLineSeries.safeSearch(s.xData, s.yData, this.point.x, this.point.y, 0.1);
                  pointY = s.yData[index];
                } else {
                  index = DaLineSeries.safeInterpolationSearch(s.xData, s.yData, this.point.x, this.point.y);
                  pointY = s.yData[index];
                }
                const point = s.data[index];
                let formattedValue: string;
                if (s.userOptions.custom?.styledRules) {
                  const styleConfigurations = selectStyleConfigurations()(store.getState()).data;
                  const styledRules = getStyledRules(s.userOptions.custom?.styledRules, styleConfigurations ?? []);
                  const content = getContent(styledRules, pointY);
                  formattedValue =
                    content.text?.length > 0
                      ? content.text
                      : getFormattedValue({
                          value: pointY,
                          baseUnit: (s.userOptions as SeriesLineOptions).tooltip?.valueSuffix,
                          userUnit: (s.userOptions as SeriesLineOptions).tooltip?.valuePrefix,
                          format: s.format ?? null,
                        });
                } else {
                  formattedValue = getFormattedValue({
                    value: pointY,
                    baseUnit: (s.userOptions as SeriesLineOptions).tooltip?.valueSuffix,
                    userUnit: (s.userOptions as SeriesLineOptions).tooltip?.valuePrefix,
                    format: s.format ?? null,
                  });
                }
                data.push({
                  id: s.index,
                  series: s,
                  formattedValue: DOMPurify.sanitize(formattedValue),
                  color: point !== undefined && point.segmentColor !== undefined ? point.segmentColor : s.color,
                  name: s.name,
                });
              });
          }
          data.forEach(({ formattedValue, color, name, series }, index) => {
            if (this.series === series) {
              toolTip += `<div style="margin-top: 0.1rem; display:flex; justify-content: space-around; background: ${
                color.length === 4
                  ? color
                      .split('')
                      .map(c => (c === '#' ? c : c + c))
                      .join('')
                  : color
              }30; border-radius: 4px; padding: 0.2rem 0.4rem;">`;
            } else {
              toolTip += '<div style="margin-top: 0.1rem; display:flex; justify-content: space-around; padding: 0.2rem 0.4rem">‎';
            }
            toolTip +=
              '<div style="text-align: left; margin-right: auto; padding-right: 2rem; max-width: 300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">' +
              `<span style="color:${color}; padding-right: 0.2rem">\u25CF</span>` +
              `<span>${name}</span></div>` +
              `<span class="tooltipPoint" style="font-weight: 600; white-space: nowrap">${formattedValue}</span></div>`;
            if (index === data.length - 1) toolTip += '</div>';
          });
          toolTip += '</div>';
          return toolTip;
        },
        valueDecimals: 2,
        backgroundColor: 'transparent',
        shadow: false,
        borderRadius:
          (typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius, 10) : theme.shape.borderRadius) * 2,
        // borderColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        positioner(boxWidth, boxHeight, point) {
          const { chart } = this;
          const { plotLeft, plotTop, plotWidth, plotHeight } = chart;
          const distanceX = point.plotX > plotWidth / 2 ? 20 : -20 - boxWidth;
          const distanceY = 20;
          const marginX = 5;
          const marginY = 5;
          const { plotX: pointX, plotY: pointY } = point;
          let x = pointX + plotLeft + (chart.inverted ? distanceX : -boxWidth - distanceX);
          let y = pointY + plotTop + distanceY;
          if (x < marginX) {
            x = marginX;
          }
          if (x + boxWidth > plotLeft + plotWidth - marginX) {
            x = plotLeft + plotWidth - boxWidth - marginX;
          }
          if (y < marginY) {
            y = marginY;
          }
          if (y + boxHeight > plotTop + plotHeight - marginY) {
            y = plotTop + plotHeight - boxHeight - marginY;
          }
          return { x, y };
        },
      },
      navigation: {
        annotationsOptions: {
          shapeOptions: {
            fill: theme.palette.grey.A700,
            stroke: theme.palette.grey.A700,
            strokeWidth: 1,
            dashStyle: 'Dash',
          },
        },
        bindingsClassName: 'highcharts-tools-container',
        bindings: {
          myComment: {
            className: 'highcharts-tools-comment',
            init() {
              this.chart.container.style.cursor = 'crosshair';
              store.dispatch(setIsCommentEdit(true));
            },
            start() {
              if (!this.chart.hoverPoint || !this.chart.hoverPoint.series.uuid) {
                if (!this.chart.hoverPoint.series.uuid) {
                  store.dispatch(
                    addToast({
                      severity: ToastSeverity.WARNING,
                      message: t('toast:noCommentForCalculation'),
                    }),
                  );
                }
                return;
              }
              store.dispatch(openComment(true));
              const xValue = this.chart.hoverPoint.x;
              const yValue = this.chart.hoverPoint.y;
              const { series } = this.chart.hoverPoint;
              const date = new Date(xValue);
              store.dispatch(
                editComment({
                  date,
                  series,
                  coords: { x: xValue, y: yValue },
                }),
              );
            },
            end() {
              this.chart.container.style.cursor = 'unset';
              store.dispatch(setIsCommentEdit(false));
            },
          },
          myLines: {
            ...Highcharts.getOptions().navigation.bindings.arrowSegment,
            className: 'highcharts-tools-segment',
            start(e) {
              this.chart.update(
                {
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      enableMouseTracking: false,
                    },
                  },
                  boost: {
                    enabled: false,
                  },
                },
                true,
              );
              const coords = this.chart.pointer.getCoordinates(e);
              const coordsX = this.utils.getAssignedAxis(coords.xAxis);
              const coordsY = this.utils.getAssignedAxis(coords.yAxis);
              const { navigation } = this.chart.options;
              const xIndex = coordsX.axis.index;
              const yIndex = coordsY.axis.index;
              if (!coordsX || !coordsY) return;
              const id = uuidv4();
              const labels = [
                {
                  point: {
                    x: undefined,
                    y: undefined,
                    xAxis: xIndex,
                    yAxis: yIndex,
                  },
                  useHTML: true,
                  text: `<div id="${id}" style="height: 20px;"></div>`,
                  overflow: 'none',
                  crop: false,
                  shape: 'rect',
                },
              ];
              const opt = Highcharts.merge(
                {
                  langKey: 'arrowSegment',
                  type: 'crookedLine',
                  labels,
                  labelOptions: {
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  },
                  id,
                  draggable: '',
                  typeOptions: {
                    line: {
                      markerEnd: 'arrow',
                    },
                    xAxis: xIndex,
                    yAxis: yIndex,
                    points: [
                      {
                        controlPoint: {
                          width: 0, // visible: false not working
                        },
                        x: coordsX.value,
                        y: coordsY.value,
                      },
                      {
                        controlPoint: {
                          width: 0, // visible: false not working
                        },
                        x: coordsX.value,
                        y: coordsY.value,
                      },
                    ],
                  },
                  events: {
                    drag(evt) {
                      const label = this.labels[0];
                      const xy = this.mouseMoveToTranslation(evt);
                      label.translate(xy.x, xy.y);
                      label.redraw(false);
                    },
                  },
                },
                navigation.annotationsOptions,
                navigation.bindings.arrowSegment.annotationsOptions,
              );
              // eslint-disable-next-line consistent-return
              return this.chart.addAnnotation(opt);
            },
            end(e, target) {
              this.chart.container.style.cursor = 'unset';

              const coords = this.chart.pointer.getCoordinates(e);
              const coordsX = this.utils.getAssignedAxis(coords.xAxis);
              const coordsY = this.utils.getAssignedAxis(coords.yAxis);
              const xIndex = coordsX.axis.options.index;
              const yIndex = coordsY.axis.options.index;
              const labels = [
                {
                  point: {
                    x: undefined,
                    y: undefined,
                    xAxis: xIndex,
                    yAxis: yIndex,
                  },
                  useHTML: true,
                  crop: false,
                },
              ];
              const activeAnnotation = this.activeAnnotation || target;
              const point0 = this.activeAnnotation?.points?.[0] ?? target?.points?.[0];
              const point1 = this.activeAnnotation?.points?.[1] ?? target?.points?.[1];
              if (point1) {
                labels[0].point.x = point1.plotX + 50;
                labels[0].point.y = point1.plotY + 50;

                const data = [];
                const duration = Math.abs((point1.x - point0.x) / 1000);
                this.chart.yAxis.forEach(axis => {
                  const end = axis.translate(point0.plotY, true);
                  const start = axis.translate(point1.plotY, true);
                  const deltaUnit = axis.unit;
                  const deltaValue = end - start;
                  const derivativeUnit = `${deltaUnit ?? ''}/${UnitName.MINUTE}`;
                  const derivativeValue = deltaValue / (duration / 60);
                  data.push({
                    delta: {
                      unit: deltaUnit,
                      value: Number.isNaN(deltaValue) ? '-' : getFormattedValue({ value: deltaValue }),
                    },
                    derivative: {
                      unit: derivativeUnit,
                      value: Number.isNaN(derivativeValue) ? '-' : getFormattedValue({ value: derivativeValue }),
                    },
                  });
                });
                activeAnnotation.update({
                  labels,
                  draggable: 'xy',
                });
                const component = document.getElementById(activeAnnotation.userOptions.id);
                const rootEnd = ReactDOM.createRoot(component);
                rootEnd.render(
                  <Wrapper>
                    <ChartSegmentLabel
                      handleDelete={() => {
                        rootEnd.unmount();
                        this?.chart?.removeSegment(activeAnnotation.userOptions.id);
                      }}
                      segment={{
                        duration,
                        data,
                      }}
                    />
                  </Wrapper>,
                );
              }
              this.chart.update(
                {
                  tooltip: {
                    enabled: true,
                  },
                  plotOptions: {
                    series: {
                      enableMouseTracking: true,
                    },
                  },
                },
                true,
              );
            },
          } as NavigationBindingsOptionsObject,
        },
      },
      stockTools: {
        gui: {
          enabled: false,
        },
      },
    };
    if (typeof htmlElement === 'string' || (htmlElement as any).nodeName) {
      super(htmlElement as HTMLElement, overrideOptions, callback);
    } else {
      super(htmlElement as Options, callback);
    }
    this.periodZoom = (options as DaChartOptions)?.periodZoom;
    this.reflowTimeout = null;
    this.batchArea = { left: null, right: null, center: null, goldenCIP: null };
    this.unitsMinMax = {};
    this.displayPeriod = (options as DaChartOptions)?.displayPeriod;
    this.isZoomed = false;
  }

  addAxis({
    color = theme.palette.grey[900],
    unit,
    apiUnit = '',
    id = '',
    pretty = false,
    hidden = false,
    min,
    max,
  }: DaAxisProps = {}): DaAxis {
    this.removeAllSegments();
    return new DaAxis(this, { color, unit, apiUnit, id, pretty, hidden, min, max });
  }

  toggleZoomType() {
    this.update(
      {
        chart: {
          zooming: {
            type: this.options.chart.zooming.type
              ? null
              : (() => {
                  if (this.options.chart.type === 'scatter') {
                    return 'xy';
                  }
                  return 'x';
                })(),
          },
          events: {
            selection: this.options.chart.events.selection
              ? null
              : (e: SelectEventObject & Event) => {
                  if (this.periodZoom) {
                    e.preventDefault();
                    if (e.xAxis !== undefined) {
                      const period = new Period({
                        from: new Date(e.xAxis[0].min),
                        to: new Date(e.xAxis[0].max),
                      });
                      store.dispatch(updatePeriod(period));
                    }
                    return false;
                  }
                  return true;
                },
          },
        },
      },
      false,
    );
  }

  addXAxis(from?: number, to?: number): DaAxis {
    this.removeAllSegments();
    const axis = super.addAxis(
      {
        type: 'datetime',
        min: from,
        max: to,
        visible: false,
        id: uuidv4(),
        lineColor: theme.palette.grey[200],
        tickColor: theme.palette.grey[200],
        alignTicks: true,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: theme.palette.grey.A200,
            fontSize: '10px',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          useHTML: true,
          formatter() {
            if (this.isFirst || this.isLast) {
              return `<div style="text-align: center">${localizedFormat(
                this.value as number,
                'p',
              )}</div><div style="text-align: center">${localizedFormat(this.value as number, 'eee PP')}</div>`;
            }
            return this.axis.defaultLabelFormatter.call(this);
          },
        },
      },
      true,
      false,
    );
    return axis as DaAxis;
  }

  addSeries({
    type,
    color,
    name = '',
    unit = '',
    style = {},
    pretty = false,
    yAxisHidden = false,
    hidden = false,
    disableBoost = false,
    ...props
  }: any = {}): DaSeries {
    let series;
    if (style.type === 'pie') {
      series = new DaPieSeries(this, { name, hidden, pretty, ...props });
    } else if (style.type === 'arearange') {
      series = new DaArearangeSeries(this, { color, name, unit, style, pretty, yAxisHidden, hidden, disableBoost, ...props });
    } else if (style.type === 'areaspline') {
      series = new DaAreasplineSeries(this, { color, name, unit, style, pretty, yAxisHidden, hidden, ...props });
    } else if (style.type === 'category') {
      series = new DaCategorySeries(this, { name, color, unit, hidden, ...props });
    } else if (style.type === 'scatter') {
      series = new DaScatterSeries(this, { name, color, unit, hidden, ...props });
    } else if (style.type === 'bar') {
      series = new DaBarSeries(this, { color, name, unit, style, pretty, yAxisHidden, hidden, ...props });
    } else if (style.type === 'coloredline') {
      series = super.addSeries({
        type: 'coloredline',
        color,
        name,
        unit,
        style,
        visible: !hidden,
        yAxisHidden,
        boostThreshold: 0,
        ...props,
      });
    } else {
      this.removeAllSegments();
      series = new DaLineSeries(this, { color, name, unit, style, pretty, yAxisHidden, hidden, disableBoost, ...props });
    }
    return series;
  }

  removeSeriesComments(daSeriesUuid: UUID): void {
    if (!daSeriesUuid) return;
    const { annotations } = this.options;
    for (let i = annotations.length - 1; i > -1; i -= 1) {
      if (annotations[i].variableUuid === daSeriesUuid) {
        this.removeAnnotation(annotations[i].id);
      }
    }
  }

  removeSeries(daSeries: DaSeries): void {
    this.removeSeriesComments(daSeries.options?.id);
    this.removeAllSegments();
    daSeries.remove(true, true, true);
  }

  removeAllSeries(): void {
    this.removeAllAnnotations();
    while (this.series.length) {
      this.series[0].remove(false, false, false);
    }
    while (this.yAxis.length) {
      this.yAxis[0].remove(false);
    }
  }

  setPeriod(period: Period): void {
    this.removeAllSegments();
    this.xAxis[0].setExtremes(period.from.getTime(), period.to.getTime(), false, false);
  }

  setUnit(unit: string, baseUnit?: string): void {
    this.xAxis[0]?.update(
      {
        labels: {
          formatter() {
            return `${this.value} ${unit !== null && unit !== undefined ? unit : (baseUnit ?? '')}`;
          },
        },
      },
      false,
    );
    // You should call redraw after calling setUnit (do not redraw here for performance reasons)
  }

  async addComment({
    date,
    message,
    series,
    coords,
    shortcut,
  }: {
    date: Date;
    message: SerializedEditorState<SerializedElementNode>;
    series: DaLineSeries;
    coords: { x: number; y: number };
    shortcut: Shortcut;
  }): Promise<void> {
    try {
      const { data } = await sdk.variable.Comment(series.uuid, {
        variableId: series.uuid,
        message,
        shortcut,
        date,
        notify: generateNotifications(message),
      });
      const newLabelHTML = `<div id="${data.uuid}"/>`;
      this.addAnnotation({
        id: data.uuid,
        variableUuid: series.uuid,
        langKey: 'comment',
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: this.yAxis.findIndex(a => a.userOptions.id === series.yAxis.userOptions.id),
              x: coords.x,
              y: coords.y,
            },
            useHTML: true,
            text: newLabelHTML,
            shape: 'connector',
          },
        ],
      });
      const component = document.getElementById(data.uuid);
      const root = ReactDOM.createRoot(component);
      root.render(
        <Wrapper>
          <ChartComment comment={data} handleDelete={() => this.deleteComment(data.uuid, root)} />
        </Wrapper>,
      );
      store.dispatch(api.util.invalidateTags(['Comments']));
    } catch (err) {
      store.dispatch(displaySdkErrorToast(err));
      console.error(err);
    }
  }

  async updateComment(comment: UpdateCommentBody): Promise<void> {
    try {
      // const notify = generateNotifications(comment.message);
      const { data } = await sdk.comment.Update(comment.uuid, {
        date: comment.date,
        message: comment.message,
        shortcut: comment.shortcut,
      });
      const commentComponent = document.getElementById(comment.uuid);
      const commentRoot = ReactDOM.createRoot(commentComponent);
      commentRoot.unmount();

      const component = document.getElementById(data.uuid);
      const root = ReactDOM.createRoot(component);
      root.render(
        <Wrapper>
          <ChartComment comment={data} handleDelete={() => this.deleteComment(data.uuid, root)} />
        </Wrapper>,
      );
    } catch (err) {
      store.dispatch(displaySdkErrorToast(err));
      console.error(err);
    }
  }

  async deleteComment(uuid: UUID, root: ReactDOM.Root): Promise<void> {
    try {
      await sdk.comment.Delete(uuid);
      root.unmount();
      this.removeAnnotation(uuid);
    } catch (err) {
      console.error(err);
      store.dispatch(displaySdkErrorToast(err));
    }
  }

  removeSegment(uuid: UUID): void {
    this.removeAnnotation(uuid);
  }

  removeAllSegments(): void {
    const { annotations } = this.options;
    if (annotations) {
      for (let i = annotations.length - 1; i > -1; i -= 1) {
        if (annotations[i].langKey === 'arrowSegment') this.removeAnnotation(annotations[i].id);
      }
    }
  }

  removeAllAnnotations(): void {
    const { annotations } = this.options;
    if (annotations) {
      for (let i = annotations.length - 1; i > -1; i -= 1) {
        this.removeAnnotation(annotations[i].id);
      }
    }
  }

  reflow(): void {
    if (this.reflowTimeout === null) {
      this.reflowTimeout = setTimeout(() => {
        if (this.options) {
          super.reflow();
        }
        this.reflowTimeout = null;
      }, 250);
    }
  }

  resetTooltip() {
    const options = {
      tooltip: {
        shared: false,
        followPointer: true,
        useHTML: true,
        xDateFormat: '%Y-%m-%d',
        animation: false,
        formatter() {
          const dateX = `${localizedFormat(this.point.x, 'eee PPpp')}`;
          let toolTip = `<div style="border: 1px solid ${theme.palette.grey[200]};box-shadow: 0 10px 15px -3px ${theme.palette.grey[900]}40, 0 4px 6px -2px ${theme.palette.grey[900]}60; background-color: white; border-radius: 4px; padding: 0.3rem; font-size: 13px">`;
          toolTip += dateX?.length
            ? `<div style="margin-bottom: 0.2rem; text-align: center; font-size: 12px; color: ${theme.palette.text.secondary}">${dateX}</div>`
            : '';
          const data = [];
          if (this.series.type === 'pie') {
            this.series.chart.series[0]?.data.forEach(point => {
              if (point.y != null) {
                data.push({
                  id: (point as unknown as { id: number }).id,
                  formattedValue: getFormattedValue({
                    value: point.y,
                    baseUnit: point.options.custom.unit,
                    userUnit: point.options.custom.selectedUnit,
                  }),
                  color: point.color,
                  name: point.name,
                });
              }
            });
          } else if (this.point.x !== undefined) {
            this.series.chart.series
              .filter(s => s.options.visible)
              .forEach(s => {
                let index: number;
                let pointY: number;
                if (this.series.type === 'scatter') {
                  index = DaLineSeries.safeSearch(s.xData, s.yData, this.point.x, this.point.y, 0.1);
                  pointY = s.yData[index];
                } else {
                  index = DaLineSeries.safeInterpolationSearch(s.xData, s.yData, this.point.x, this.point.y);
                  pointY = s.yData[index];
                }
                const point = s.data[index];
                data.push({
                  id: s.index,
                  series: s,
                  formattedValue: getFormattedValue({
                    value: pointY,
                    baseUnit: s.userOptions.tooltip?.valueSuffix,
                    userUnit: s.userOptions.tooltip?.valuePrefix,
                    format: s.format ?? null,
                  }),
                  color: point?.segmentColor ?? s.color,
                  name: s.name,
                });
              });
          }
          data.forEach(({ formattedValue, color, name, series }, index) => {
            if (this.series === series) {
              toolTip += `<div style="margin-top: 0.1rem; display:flex; justify-content: space-around; background: ${
                color.length === 4
                  ? color
                      .split('')
                      .map(c => (c === '#' ? c : c + c))
                      .join('')
                  : color
              }30; border-radius: 4px; padding: 0.2rem 0.4rem;">`;
            } else {
              toolTip += '<div style="margin-top: 0.1rem; display:flex; justify-content: space-around; padding: 0.2rem 0.4rem">‎';
            }
            toolTip +=
              '<div style="text-align: left; margin-right: auto; padding-right: 2rem; max-width: 300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">' +
              `<span style="color:${color}; padding-right: 0.2rem">\u25CF</span>` +
              `<span>${name}</span></div>` +
              `<span class="tooltipPoint" style="font-weight: 600; white-space: nowrap">${formattedValue}</span></div>`;
            if (index === data.length - 1) toolTip += '</div>';
          });
          toolTip += '</div>';
          return toolTip;
        },
        valueDecimals: 2,
        backgroundColor: 'transparent',
        shadow: false,
        borderRadius:
          (typeof theme.shape.borderRadius === 'string' ? parseInt(theme.shape.borderRadius, 10) : theme.shape.borderRadius) * 2,
        // borderColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        positioner(boxWidth, boxHeight, point) {
          const { chart } = this;
          const { plotLeft, plotTop, plotWidth, plotHeight } = chart;
          const distanceX = point.plotX > plotWidth / 2 ? 20 : -20 - boxWidth;
          const distanceY = 20;
          const marginX = 5;
          const marginY = 5;
          const { plotX: pointX, plotY: pointY } = point;
          let x = pointX + plotLeft + (chart.inverted ? distanceX : -boxWidth - distanceX);
          let y = pointY + plotTop + distanceY;
          if (x < marginX) {
            x = marginX;
          }
          if (x + boxWidth > plotLeft + plotWidth - marginX) {
            x = plotLeft + plotWidth - boxWidth - marginX;
          }
          if (y < marginY) {
            y = marginY;
          }
          if (y + boxHeight > plotTop + plotHeight - marginY) {
            y = plotTop + plotHeight - boxHeight - marginY;
          }
          return { x, y };
        },
      },
    };
    this.update(options, true);
  }

  setTooltipToMultipleXAxis(from?: number) {
    const options = {
      tooltip: {
        formatter() {
          const duration = from ? (this.series.chart.xAxis[0].toValue(this.point.plotX, true) - from) / 1000 : -1;
          const dateX = duration > 0 ? shortDurationDisplay(duration) : '';
          let tooltip = `<div style="border: 1px solid ${theme.palette.grey[200]};box-shadow: 0 10px 15px -3px ${theme.palette.grey[900]}40, 0 4px 6px -2px ${theme.palette.grey[900]}60; background-color: white; border-radius: 4px; padding: 0.3rem; font-size: 13px"><table style="border-collapse: collapse">`;
          tooltip += `<thead><div style="margin-bottom: 0.2rem; text-align: center; font-size: 12px; color: ${theme.palette.text.secondary}">${dateX}</div></thead><tbody>`;
          const data = [];
          if (this.point.x !== undefined) {
            this.series.chart.series
              .filter(s => s.options.visible)
              .forEach(s => {
                const index = DaLineSeries.safeInterpolationSearch(s.xData, s.yData, this.point.x, this.point.y);
                const pointY = s.yData[index];
                const point = s.data[index];
                const indexOfData = data.findIndex(d => d.series.uuid === s.uuid);
                if (indexOfData > -1) {
                  data[indexOfData].formattedValues.push(
                    getFormattedValue({
                      value: pointY,
                      baseUnit: s.userOptions.tooltip?.valueSuffix,
                      userUnit: s.userOptions.tooltip?.valuePrefix,
                    }),
                  );
                } else {
                  data.push({
                    id: s.index,
                    series: s,
                    formattedValues: [
                      getFormattedValue({
                        value: pointY,
                        baseUnit: s.userOptions.tooltip?.valueSuffix,
                        userUnit: s.userOptions.tooltip?.valuePrefix,
                      }),
                    ],
                    color: point !== undefined && point.segmentColor !== undefined ? point.segmentColor : s.color,
                    name: s.name,
                  });
                }
              });
          }
          data.forEach(({ formattedValues, color, name, series }, index) => {
            if (this.series.uuid === series.uuid) {
              tooltip += `<tr style="margin-top: 0.1rem; background: ${
                color.length === 4
                  ? color
                      .split('')
                      .map(c => (c === '#' ? c : c + c))
                      .join('')
                  : color
              }30;">`;
            } else {
              tooltip += '<tr style="margin-top: 0.1rem;">';
            }
            tooltip +=
              '<td style="border-radius: 4px 0 0 4px;text-align: left; margin-right: auto; padding: 0.2rem 2rem 0.2rem 0.4rem; max-width: 300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">' +
              `<span style="color:${color}; padding-right: 0.2rem">\u25CF</span>` +
              `<span>${name}</span></td>`;
            formattedValues.forEach((fV, i) => {
              tooltip += `<td style="text-align: right; padding: 0.2rem 0.4rem; ${
                i === formattedValues.length - 1 ? 'border-radius: 0 4px 4px 0;' : ''
              }${
                i !== 0 ? `border-left: solid 1px ${theme.palette.grey[600]}` : ''
              }"><span class="tooltipPoint" style="font-weight: 600; white-space: nowrap;">${fV}</span></td>`;
            });
            tooltip += '</tr>';
            if (index === data.length - 1) tooltip += '</tbody>';
          });
          tooltip += '</table></div>';
          return tooltip;
        },
      },
    };
    this.update(options, false);
  }

  setToTimeAxis() {
    this.resetTooltip();
    const options: Options = {
      xAxis: {
        tickPositioner() {
          const { tickPositions } = this;
          const { displayPeriod, isZoomed } = this.chart as DaChart;
          if (displayPeriod && !isZoomed) {
            tickPositions.splice(0, 1);
            tickPositions.splice(tickPositions.length - 1, 1);
            tickPositions[0] = displayPeriod.from.getTime();
            tickPositions[tickPositions.length - 1] = displayPeriod.to.getTime();
          }
          return tickPositions;
        },
        events: {
          setExtremes(event) {
            if (!event.min && !event.max) {
              (this.chart as DaChart).isZoomed = false;
            } else {
              (this.chart as DaChart).isZoomed = true;
            }
          },
        },
        type: 'datetime',
        lineColor: theme.palette.grey[200],
        tickColor: theme.palette.grey[200],
        alignTicks: true,
        title: {
          text: null,
        },
        crosshair: {
          width: 1,
          color: theme.palette.grey[600],
          dashStyle: 'Dash',
        },
        labels: {
          formatter() {
            if (this.isFirst || this.isLast) {
              return `<div style="text-align: center">${localizedFormat(
                this.value as number,
                'p',
              )}</div><div style="text-align: center">${localizedFormat(this.value as number, 'eee PP')}</div>`;
            }
            return this.axis.defaultLabelFormatter.call(this);
          },
        },
      },
    };
    this.update(options, true);
  }

  setToDurationAxis(from: number): void {
    this.setTooltipToMultipleXAxis(from);
    const options: Options = {
      xAxis: {
        tickPositioner() {
          const newTo = this.chart.xAxis[0].max;
          let diff = (newTo - from) / 1000 / 60;
          if (diff > 1440) diff /= 12;
          if (diff > 300) diff /= 12;
          if (diff > 120) diff /= 60;
          if (diff > 60) diff /= 2;
          if (diff > 10) diff /= 5;
          const positions = [];
          if (this.options.visible) {
            let tick = from;
            const increment = Math.ceil((newTo - from) / diff / 1000 / 60) * 1000 * 60;
            if (newTo !== null && from !== null) {
              for (tick; tick - increment <= newTo; tick += increment) {
                positions.push(tick);
              }
            }
          }
          return positions;
        },
        labels: {
          style: {
            color: theme.palette.grey.A200,
            fontSize: '10px',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          useHTML: true,
          formatter() {
            return `<div style="text-align: center">${shortDurationDisplay(((this.value as number) - from) / 1000)}</div>`;
          },
        },
      },
    };
    this.update(options, true);
  }

  drawBatch(from: number, to: number, target?: number): void {
    this.xAxis[0].addPlotBand({
      from,
      to,
      color: theme.palette.white,
    });
    this.xAxis[0].addPlotLine({
      value: from,
      width: 1,
      zIndex: 3,
      color: 'lightgrey',
    });
    if (this.xAxis.length === 1) {
      this.xAxis[0].addPlotLine({
        value: to,
        width: 1,
        zIndex: 3,
        color: 'lightgrey',
      });
    }
    if (target) {
      this.xAxis[0].addPlotLine({
        color: theme.palette.picker.red,
        zIndex: 3,
        dashStyle: 'LongDash',
        value: from + target * 1000,
        width: 1,
        label: {
          text: i18next.t('batch:input.target'),
          style: {
            fontSize: '10px',
            color: theme.palette.picker.red,
            backgroundColor: theme.palette.white,
            textAlign: 'right',
          },
          y: 40,
        },
      });
    }
    const newOptions: Options = {
      chart: {
        plotBackgroundImage:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnPjxkZWZzPjxwYXR0ZXJuIGlkPSdwaW5zdHJpcGUnIHBhdHRlcm5Vbml0cz0ndXNlclNwYWNlT25Vc2UnIHBhdHRlcm5UcmFuc2Zvcm09J3JvdGF0ZSgyMCknIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+PGxpbmUgeDE9JzEwJyB5MT0nMCcgeDI9JzEwJyB5Mj0nMjAnIHN0cm9rZT0nbGlnaHRncmV5JyBzdHJva2Utd2lkdGg9JzEnIC8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyBmaWxsPSd1cmwoI3BpbnN0cmlwZSknIC8+PC9zdmc+',
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
    };
    if (this.xAxis.length > 1) {
      this.setToDurationAxis(from);
    }
    this.update(newOptions, true);
  }

  updateDisplayPeriod(period: { from: Date; to: Date }, redraw?: boolean): void {
    this.displayPeriod = period;
    if (redraw) {
      super.redraw(false);
    }
  }
}
